import { useQuery, useMutation } from "@apollo/client";
import Button from "components/Button";
import Calendar from "components/Calendar";
import Page from "components/Page";
import moment from "moment";
import { useEffect, useState } from "react";
import { FETCH_SUPPLIER_OFF_DATES, SUBMIT_SUPPLIER_OFF_DATES } from "./graphql";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { toast } from "react-toastify";
import { parseError } from "Apollo";

const Holidays = () => {
  const [year, setYear] = useState(moment().year());
  const [selectedDates, setSelectedDates] = useState([]);

  const { loading, error, data } = useQuery(FETCH_SUPPLIER_OFF_DATES);
  const [submit, submitRes] = useMutation(SUBMIT_SUPPLIER_OFF_DATES, {
    onCompleted() {
      toast.success("提交成功，我们会收到您的节假日设置。");
    },
    onError(error) {
      toast.error(parseError(error));
    },
  });

  useEffect(() => {
    if (data) setSelectedDates(data.me.supplier.offDates.map((i) => i.date));
  }, [data]);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  return (
    <Page
      title="Holidays"
      backTo="./.."
      containerClass="flex-1 overflow-auto"
      topbarRightView={
        <Button
          loading={submitRes.loading}
          onClick={() => {
            submit({ variables: { offDates: selectedDates } });
          }}
        >
          Save 保存
        </Button>
      }
    >
      <div className="m-8 opacity-70 text-sm">
        <p>
          We will consider longer product time if there is holidays in our productions.
          如果您设置了节假日，我们会在生产时考虑到节假日的影响，从而估计更准确的生产时间。
        </p>
        <p>You could select the holidays below and save to update your production calendar. 您可以在下面的日历中点击选择并保存从而更新你的生产日历。</p>
      </div>
      <div className="m-8">
        <Calendar year={year} setYear={setYear} selectedDates={selectedDates} setSelectedDates={setSelectedDates} />
      </div>
    </Page>
  );
};

export default Holidays;
